import logo1 from "../assets/images/blueocean-merdeka.svg"
import logo2 from "../assets/images/minos-merdeka.svg"
import logo3 from "../assets/images/cronoscap-merdeka.svg"
import logo4 from "../assets/images/funda-merdeka-dark.svg"
import logo5 from "../assets/images/360-merdeka.svg"
import logo6 from "../assets/images/antos.svg"
import logo7 from "../assets/images/equinox_merdeka_logo_light.svg"
import logo8 from "../assets/images/exco-merdeka.svg"
import logo9 from "../assets/images/ridgeway_secondary_dark.png"
import logo10 from "../assets/images/allium_logo_secondary_dark.png"

const Keys = {
    // brandName: "Blue Ocean",
    // API_URL:"https://cmsapi.blueoceanfin.com",
    // SERVER_URL:"https://cmsapi.blueoceanfin.com",
    // MEDIA_URL:"https://cmsapi.blueoceanfin.com/media/",
    // LOGO: logo1,
    // LOGO_CLASSNAME: "blueocean-logo",
    // background: "#ffffff"

    // brandName: "Blue Ocean",
    // API_URL:"https://cmsapi.blueocex.com",
    // SERVER_URL:"https://cmsapi.blueocex.com",
    // MEDIA_URL:"https://cmsapi.blueocex.com/media/",
    // LOGO: logo1,
    // LOGO_CLASSNAME: "blueocean-logo",
    // background: "#ffffff"

    // brandName: "Minos Futures",
    // API_URL: "https://cmsapi.minosfutures.com",
    // SERVER_URL: "https://cmsapi.minosfutures.com",
    // MEDIA_URL: "https://cmsapi.minosfutures.com/media/",
    // LOGO: logo2,
    // LOGO_CLASSNAME: "minos-logo",
    // background: "#001D19"

    // brandName: "Cronos",
    // API_URL:"https://cmsapi.cronoscap.com",
    // SERVER_URL:"https://cmsapi.cronoscap.com",
    // MEDIA_URL:"https://cmsapi.cronoscap.com/media/",
    // LOGO: logo3,
    // LOGO_CLASSNAME: "cronos-logo",
    // background: "#131313"

    // brandName: "Cronos",
    // API_URL:"https://cmsapi.cronoscap.net",
    // SERVER_URL:"https://cmsapi.cronoscap.net",
    // MEDIA_URL:"https://cmsapi.cronoscap.net/media/",
    // LOGO: logo3,
    // LOGO_CLASSNAME: "cronos-logo",
    // background: "#131313"

    // brandName: "Funda Markets",
    // API_URL:"https://cmsapi.fundamarkets.net",
    // SERVER_URL:"https://cmsapi.fundamarkets.net",
    // MEDIA_URL:"https://cmsapi.fundamarkets.net/media/",
    // LOGO: logo4,
    // LOGO_CLASSNAME: "funda-logo",
    // background: "#ffffff"

    // brandName: "Funda Markets",
    // API_URL:"https://cmsapi.fundamarkets.com",
    // SERVER_URL:"https://cmsapi.fundamarkets.com",
    // MEDIA_URL:"https://cmsapi.fundamarkets.com/media/",
    // LOGO: logo4,
    // LOGO_CLASSNAME: "funda-logo",
    // background: "#ffffff"

    // brandName: "360tradehub",
    // API_URL:"https://cmsapi.360tradehub.com",
    // SERVER_URL:"https://cmsapi.360tradehub.com",
    // MEDIA_URL:"https://cmsapi.360tradehub.com/media/",
    // LOGO: logo5,
    // LOGO_CLASSNAME: "tradehub360-logo",
    // background: "#ffffff"

    // brandName: "360tradehub",
    // API_URL:"https://cmsapi.tradehub360.com",
    // SERVER_URL:"https://cmsapi.tradehub360.com",
    // MEDIA_URL:"https://cmsapi.tradehub360.com/media/",
    // LOGO: logo5,
    // LOGO_CLASSNAME: "tradehub360-logo",
    // background: "#ffffff"

    // brandName: "Antospnc",
    // API_URL:"https://cmsapi.antospnc.com",
    // SERVER_URL:"https://cmsapi.antospnc.com",
    // MEDIA_URL:"https://cmsapi.antospnc.com/media/",
    // LOGO: logo6,
    // LOGO_CLASSNAME: "antos-logo",
    // background: "#ffffff"

    // brandName: "Antos",
    // API_URL:"https://cmsapi.antos.io",
    // SERVER_URL:"https://cmsapi.antos.io",
    // MEDIA_URL:"https://cmsapi.antos.io/media/",
    // LOGO: logo6,
    // LOGO_CLASSNAME: "antos-logo",
    // background: "#ffffff"

    // brandName: "Equinox Markets",
    // API_URL:"https://cmsapi.equinoxmarkets.com",
    // SERVER_URL:"https://cmsapi.equinoxmarkets.com",
    // MEDIA_URL:"https://cmsapi.equinoxmarkets.com/media/",
    // LOGO: logo7,
    // LOGO_CLASSNAME: "equinox-logo",
    // background: "#ffffff"

    // brandName: "Equinox Markets",
    // API_URL:"https://cmsapi.equinox-markets.com",
    // SERVER_URL:"https://cmsapi.equinox-markets.com",
    // MEDIA_URL:"https://cmsapi.equinox-markets.com/media/",
    // LOGO: logo7,
    // LOGO_CLASSNAME: "equinox-logo",
    // background: "#ffffff"

    // brandName: "Excotrades",
    // API_URL:"https://cmsapi.excotrades.com",
    // SERVER_URL:"https://cmsapi.excotrades.com",
    // MEDIA_URL:"https://cmsapi.excotrades.com/media/",
    // LOGO: logo8,
    // LOGO_CLASSNAME: "excotrades-logo",
    // background: "#000000"

    // brandName: "Alliuminternational",
    // API_URL:"https://cmsapi.alliuminternational.com",
    // SERVER_URL:"https://cmsapi.alliuminternational.com",
    // MEDIA_URL:"https://cmsapi.alliuminternational.com/media/",
    // LOGO: logo10,
    // LOGO_CLASSNAME: "allium-logo",
    // background: "#0B0B17"

    // brandName: "Alliuminternational",
    // API_URL: "https://cmsapi-stg.alliuminternational.com",
    // SERVER_URL: "https://cmsapi-stg.alliuminternational.com",
    // MEDIA_URL: "https://cmsapi-stg.alliuminternational.com/media/",
    // LOGO: logo10,
    // LOGO_CLASSNAME: "allium-logo",
    // background: "#0B0B17"

    // brandName: "Ridgeway Markets",
    // API_URL:"https://cmsapi.ridgewaymarkets.com",
    // SERVER_URL:"https://cmsapi.ridgewaymarkets.com",
    // MEDIA_URL:"https://cmsapi.ridgewaymarkets.com/media/",
    // LOGO: logo9,
    // LOGO_CLASSNAME: "ridgeway-logo",
    // background: "#100503"

    // brandName: "Minos Test",
    // API_URL:"",
    // SERVER_URL:"http://localhost:5000",
    // MEDIA_URL:"http://localhost:5000/media/",
    // LOGO: logo2,
    // LOGO_CLASSNAME: "minos-logo",
    // background: "#ffffff"

    //Staging

    // brandName: "Cronos",
    // API_URL:"https://cmsapi-stg.cronoscap.com",
    // SERVER_URL:"https://cmsapi-stg.cronoscap.com",
    // MEDIA_URL:"https://cmsapi-stg.cronoscap.com/media/",
    // LOGO: logo3,
    // LOGO_CLASSNAME: "cronos-logo",
    // background: "#131313"

    // brandName: "Cronos",
    // API_URL:"https://cmsapi-stg.cronoscap.net",
    // SERVER_URL:"https://cmsapi-stg.cronoscap.net",
    // MEDIA_URL:"https://cmsapi-stg.cronoscap.net/media/",
    // LOGO: logo3,
    // LOGO_CLASSNAME: "cronos-logo",
    // background: "#131313"

    // brandName: "Blue Ocean",
    // API_URL:"https://cmsapi-stg.blueoceanfin.com",
    // SERVER_URL:"https://cmsapi-stg.blueoceanfin.com",
    // MEDIA_URL:"https://cmsapi-stg.blueoceanfin.com/media/",
    // LOGO: logo1,
    // LOGO_CLASSNAME: "blueocean-logo",
    // background: "#ffffff"

    // brandName: "Blue Ocean",
    // API_URL:"https://cmsapi-stg.blueocex.com",
    // SERVER_URL:"https://cmsapi-stg.blueocex.com",
    // MEDIA_URL:"https://cmsapi-stg.blueocex.com/media/",
    // LOGO: logo1,
    // LOGO_CLASSNAME: "blueocean-logo",
    // background: "#ffffff"

    // brandName: "Funda Markets",
    // API_URL:"https://cmsapi-stg.fundamarkets.net",
    // SERVER_URL:"https://cmsapi-stg.fundamarkets.net",
    // MEDIA_URL:"https://cmsapi-stg.fundamarkets.net/media/",
    // LOGO: logo4,
    // LOGO_CLASSNAME: "funda-logo",
    // background: "#ffffff"

    // brandName: "Funda Markets",
    // API_URL:"https://cmsapi-stg.fundamarkets.com",
    // SERVER_URL:"https://cmsapi-stg.fundamarkets.com",
    // MEDIA_URL:"https://cmsapi-stg.fundamarkets.com/media/",
    // LOGO: logo4,
    // LOGO_CLASSNAME: "funda-logo",
    // background: "#ffffff"

    // brandName: "Antospnc",
    // API_URL:"https://cmsapi-stg.antospnc.com",
    // SERVER_URL:"https://cmsapi-stg.antospnc.com",
    // MEDIA_URL:"https://cmsapi-stg.antospnc.com/media/",
    // LOGO: logo6,
    // LOGO_CLASSNAME: "antos-logo",
    // background: "#ffffff"

    // brandName: "Antos",
    // API_URL:"https://cmsapi-stg.antos.io",
    // SERVER_URL:"https://cmsapi-stg.antos.io",
    // MEDIA_URL:"https://cmsapi-stg.antos.io/media/",
    // LOGO: logo6,
    // LOGO_CLASSNAME: "antos-logo",
    // background: "#ffffff"

    // brandName: "Excotrades",
    // API_URL:"https://cmsapi-stg.excotrades.com",
    // SERVER_URL:"https://cmsapi-stg.excotrades.com",
    // MEDIA_URL:"https://cmsapi-stg.excotrades.com/media/",
    // LOGO: logo8,
    // LOGO_CLASSNAME: "excotrades-logo",
    // background: "#000000"

    // brandName: "360tradehub",
    // API_URL:"https://cmsapi-stg.360tradehub.com",
    // SERVER_URL:"https://cmsapi-stg.360tradehub.com",
    // MEDIA_URL:"https://cmsapi-stg.360tradehub.com/media/",
    // LOGO: logo5,
    // LOGO_CLASSNAME: "tradehub360-logo",
    // background: "#ffffff"

    // brandName: "360tradehub",
    // API_URL:"https://cmsapi-stg.360th.net",
    // SERVER_URL:"https://cmsapi-stg.360th.net",
    // MEDIA_URL:"https://cmsapi-stg.360th.net/media/",
    // LOGO: logo5,
    // LOGO_CLASSNAME: "tradehub360-logo",
    // background: "#ffffff"

    brandName: "Minos Futures",
    API_URL: "https://cmsapi-stg.minosfutures.com",
    SERVER_URL: "https://cmsapi-stg.minosfutures.com",
    MEDIA_URL: "https://cmsapi-stg.minosfutures.com/media/",
    LOGO: logo2,
    LOGO_CLASSNAME: "minos-logo",
    background: "#001D19"


};

export default Keys;

